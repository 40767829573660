import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import ContactForm from "../../components/contact-form";
import Layout from "../../components/layout";
import Seo from "../../components/seo"
import PortfolioFeed from "../../components/portfolio-feed";
import MarketResearchIcon from '../../icons/market-research.svg';
import SiteMapIcon from '../../icons/sitemap.svg';
import Carousel from 'react-bootstrap/Carousel'
import Quote from "../../components/quote";


const ImageGallery1 = () => (
  <Carousel fade>
    <Carousel.Item>
      <StaticImage
        src="../../images/portfolio/pawc/PAWC-logo-mockup2.jpeg"
        alt="Log Mockup"
      />
    </Carousel.Item>
    <Carousel.Item>
      <StaticImage
        src="../../images/portfolio/pawc/PAWC_brand_guide_page_1.jpeg"
        alt="Brand Guide Page 1"
      />
    </Carousel.Item>
    <Carousel.Item>
      <StaticImage
        src="../../images/portfolio/pawc/PAWC_brand_guide_page_2.jpeg"
        alt="Brand Guide Page 2"
      />
    </Carousel.Item>
  </Carousel>
)

const ImageGallery2 = () => (
  <Carousel fade>
    <Carousel.Item>
      <StaticImage
        src="../../images/portfolio/pawc/PAWC-brochure-cover.jpeg"
        alt="Brochure cover"
      />
    </Carousel.Item>
    <Carousel.Item>
      <StaticImage
        src="../../images/portfolio/pawc/PAWC-brochure-inside.jpeg"
        alt="Brochure inside"
      />
    </Carousel.Item>
    <Carousel.Item>
      <StaticImage
        src="../../images/portfolio/pawc/PAWC-brochure-outside.jpeg"
        alt="Brochure outside"
      />
    </Carousel.Item>
  </Carousel>
)

const PortfolioHeader = () => (
  <section className="bg-dark py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <h3 className="text-secondary-light">Client</h3>
              <p className="text-white">Psychiatric Alternatives</p>
            </div>
            <div className="col-12 mt-4 mt-lg-5">
              <h3 className="text-secondary-light">Our Role</h3>
              <p className="text-white">
                UX/UI Design
                <br></br>
                Graphic Design
                <br></br>
                Website Design
                <br></br>
                Logo Design
                <br></br>
                Identity Design
                <br></br>
                Branding
                <br></br>
                Custom Software Development
                <br></br>
                Marketing Materials
                <br></br>
                Monitoring and Maintenance
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 mt-5 mt-lg-0">
          <h3 className="text-secondary-light">Summary</h3>
          <h2 className="text-white mb-0">
            729 solutions has become this client’s one-stop-shop full stack development team, design consultant, and
            maintenance expert. we keep their systems running smoothly at all times.
          </h2>
        </div>
      </div>
    </div>
  </section>
);

const PortfolioVideo = () => (
  <section className="bg-light pt-6">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <video controls style={{maxWidth: '100%'}}>
            <source type="video/mp4" src="https://729solutions.com/wp-content/uploads/2020/07/Connect-Mi-TV-Flow.mp4"/>
          </video>
        </div>
      </div>
    </div>
  </section>
);

const ProjectGoals = () => (
  <section>
    <div className="container py-6">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Problem Statement</h2>
          <p>Psychiatric Alternatives needed to find a development team to take over existing systems that had been
            developed over time and needed upgrades and enhancements. They contacted 729 Solutions hoping we could step
            in and maintain their existing frontend and backend code, while providing a constant rollout of new
            features.</p>
          <p>In addition, they needed a full rebranding of their visual identity. Their logo, colors, and general
            appearance looked outdated and their user experience was not intuitive. They longed for a one-stop-shop to
            cover all the bases at once.</p>
        </div>
        <div className="col-12 col-lg-6 mt-5 mt-lg-0">
          <h2>project goals</h2>
          <ol className="text-primary m-0 pl-3">
            <li><span className="text-dark">Enhance system stability.</span></li>
            <li><span className="text-dark">Provide a pathway to meet compliance requirements.</span>
            </li>
            <li><span className="text-dark">Maintain the system and resolve issues in a timely manner.</span></li>
            <li><span className="text-dark">Implement new feature sets.</span></li>
            <li><span className="text-dark">Improve performance.</span>
            </li>
            <li><span className="text-dark">Improve visual branding.</span>
            </li>
            <li><span className="text-dark">Provide an innovative and intuitive user interface.</span>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
);


const DevelopmentLanguagesAndNeeds = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-2">Software Used</h2>
              <div className="row">
                <div className="col-9">
                  <div className="row">
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/adobe-xd.png"
                        alt="Adobe Xd"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/adobe-id.png"
                        alt="Adobe Id"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div className="col-2 pr-0">
                      <StaticImage
                        src="../../images/tools/adobe-illustrator.png"
                        alt="Adobe Ai"
                        width={50}
                        height={50}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <h2 className="mt-5 mb-2">development languages used</h2>
              <p className="mb-0">JavaScript</p>
              <p className="mb-0">HTML 5</p>
              <p className="mb-0">CSS 3</p>
              <p className="mb-0">PHP</p>
            </div>
            <div className="col-12">
              <h2 className="mt-5 mb-2">
                tools used
              </h2>
              <p className="mb-0">AWS</p>
              <p className="mb-0">Browsershots</p>
              <p className="mb-0">New Relic</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-2 mt-5 mt-lg-0">Databases</h2>
              <p className="mb-0">MySQL</p>
            </div>
            <div className="col-12">
              <h2 className="mb-2 mt-5">Research Methods</h2>
              <p className="d-flex align-items-center">
                <MarketResearchIcon width={55} height={55} className="text-muted"/>
                <span className="pl-4">Market Research Analysis</span>
              </p>
              <p className="d-flex align-items-center">
                <SiteMapIcon width={55} height={55} className="text-muted"/>
                <span className="pl-4 ">Information Architecture</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const ProjectForm = ({location}) => (
  <section className="bg-light py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Have a Project?</h2>
          <p className="pr-5">We'd love to hear about it!</p>
          <small className="text-muted pr-5">
            Email us at{" "}
            <a href="mailto:solicitations@729solutions.com">
              solicitations@729solutions.com
            </a>{" "}
            for solicitations regarding partnerships, linkbacks, or
            subcontracting opportunities.
          </small>
        </div>
        <div className="col-12 col-lg-6">
          <ContactForm showHowYouFindUs={false} buttonText="Submit" location={location}/>
        </div>
      </div>
    </div>
  </section>
);


const ProductImage = () => (
  <section>
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <StaticImage
            src="../../images/portfolio/pawc/hero-Img.png"
            alt="PAWC Hero"
          />
        </div>
      </div>
    </div>
  </section>

)

const Showcase = () => (
  <section className="bg-light ">
    <div className="container py-6">
      <div className="row">
        <div className="col">
          <h2>Showcase</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <StaticImage
            src="../../images/portfolio/pawc/PAWC-home.jpeg"
            alt="Home"
          />
          <StaticImage
            className="mt-4"
            src="../../images/portfolio/pawc/PAWC-our-team.jpeg"
            alt="Our Team"
          />
        </div>
        <div className="col-12 col-lg-4 mt-4 mt-lg-0">
          <StaticImage
            src="../../images/portfolio/pawc/PAWC-Classes.jpeg"
            alt="Classes"
          />
          <StaticImage
            className="mt-4"
            src="../../images/portfolio/pawc/PAWC-billing.jpeg"
            alt="Billing"
          />
          <StaticImage
            className="mt-4"
            src="../../images/portfolio/pawc/PAWC-insurance.jpeg"
            alt="Insirance"
          />
        </div>
        <div className="col-12 col-lg-4 mt-4 mt-lg-0">
          <StaticImage
            src="../../images/portfolio/pawc/PAWC-patient-portal-home.jpeg"
            alt="Portal"
          />
          <StaticImage
            className="mt-4"
            src="../../images/portfolio/pawc/PAWC-patient-portal-login-screen.jpeg"
            alt="Login"
          />
          <StaticImage
            className="mt-4"
            src="../../images/portfolio/pawc/PAWC-patient-portal-cancel-app.jpeg"
            alt="Cancellation"
          />
        </div>
      </div>
      <div className="row pt-6">
        <div className="col-12 col-lg-4">
          <ImageGallery1/>
        </div>
        <div className="col-12 col-lg-8 mt-5 mt-lg-0">
          <ImageGallery2/>
        </div>
      </div>
    </div>
  </section>
)

const KeyFocusAreas = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2 className="pb-3 m-0">key focus areas</h2>
          <p>How can we make the system as stable as possible?</p>
          <p>How can we improve the user experience?</p>
          <p>How can we keep up with changes to medical billing?</p>
          <p>How can we unify brand identity across all platforms and marketing channels?</p>
        </div>
        <div className="col-12 col-lg-6 mt-5 mt-lg-0">
          <h2 className="mb-2">core client needs</h2>
          <div className="row">
            <div className="col-12 mb-4">
              <p><strong>Uptime</strong><br></br>The system must remain responsive and available during business hours
                with as little downtime as possible.

              </p>
            </div>
            <div className="col-12 mb-4">
              <p><strong>Responsiveness</strong><br></br>The team maintaining and improving the system must be able to
                respond to needs quickly and be able to effectively deal with outages or new feature requests.</p>
            </div>
            <div className="col-12 mb-4">
              <p><strong>Stability</strong><br></br>The team taking over the system must be an established and known
                team that will reliably stick around for years into the future.</p>
            </div>

          </div>

        </div>
      </div>
    </div>
  </section>
);


const Learnings = () => (
  <section className="py-6">
    <div className="container">
      <div className="row">
        <div className="col">
          <h2 className="mb-2">learnings</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <p>This was an interesting challenge for both our design and development teams. It involved working within the
            box of the client’s boundaries while thinking outside the box and finding creative solutions.</p>
          <p>On the development side of things, the original base code did not come with much in the way of physical
            documentation or examples. We took the initiative to meet with the original code writer to gather their
            knowledge. From there, we made massive improvements to the system and added new functionality.</p>
          <p>For the design team, it was about finding a balance between the clean, professional look that’s expected in
            the medical field with the client’s desire to add some uniqueness and personality to their brand.</p>
        </div>
        <div className="col-12 col-lg-6">
          <p>We found the perfect blend between these two requirements and delivered a fresh identity that instantly
            connected on a deep level with Psychiatric Alternatives and their clients.</p>
          <p>The fact that 729 offers a highly-skilled team and a wide variety of development tools made a huge
            difference in effective problem-solving for this client.</p>
          <p>Overall, this project allowed 729 Solutions to grow as a provider and showed that we know how to deploy the
            best methodologies to solve complex challenges. It also proved that we can offer extremely fast turnaround
            times when the need arises, which truly delights our clients.</p>
        </div>
      </div>
    </div>
  </section>
);

const ProjectQuote = () => (
  <section className="bg-dark py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <Quote quote="We appreciate all of you so much. You’re work and support is top notch."
                 company="Psychiatric Alternatives" name="Jillian Janison" dark/>
        </div>
        <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center mt-5 mt-lg-0">
          <div className="w-75">
            <StaticImage
              src="../../images/clients/psychiatic-alternatives_transparent.png"
              alt="PAWC Logo"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);


const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Psychiatric Alternatives (PAWC)" 
      />
      <PortfolioHeader/>
      <ProductImage/>
      <ProjectGoals/>
      <DevelopmentLanguagesAndNeeds/>
      <Showcase/>
      <KeyFocusAreas/>
      <ProjectQuote/>
      <Learnings/>
      <ProjectForm location={location}/>
      <PortfolioFeed className="my-6"/>
    </Layout>
  );
};

export default Index;
